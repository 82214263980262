import React from 'react'
import { useTranslation } from 'react-i18next'
import LinkButton from '~/components/LinkButton'
import Typography from '~/components/Typography'
import { ROUTES } from '~/constants/routes'
import { getSubscriptionLinkTo } from '~/utils/booking'
import { trackEvent } from '~/utils/tracking'
import type { SlotPickContext } from '../SlotPicker.types'

export type SlotPickerLaterProps = {
  context: SlotPickContext
  isInvoiceNeeded: boolean
  isLogged: boolean
}

const SlotPickerLater = ({
  context,
  isInvoiceNeeded,
  isLogged
}: SlotPickerLaterProps) => {
  const { t } = useTranslation()

  const handleClick = () => {
    trackEvent('SELECT_SLOT_SKIP', {
      context
    })
  }

  return (
    <div className="flex flex-col">
      <div className="flex flex-col gap-y-3 px-8 py-5 text-center lg:py-8 lg:text-left">
        <Typography variant="h5" className="!font-medium">
          {t('booking:bookLaterTitle')}
        </Typography>
        <Typography className="text-balance text-black/60" variant="body2">
          {t('booking:bookLaterDescription')}
        </Typography>
      </div>
      <div className="border-black/10 px-8 py-6 md:border-t">
        <LinkButton
          to={getSubscriptionLinkTo({
            pathname: isLogged ? ROUTES.subscription_payment : ROUTES.signup,
            isInvoiceNeeded
          })}
          rounded="full"
          color="primary"
          fullWidth
          size="lg"
          onClick={handleClick}
        >
          {t(
            isLogged
              ? 'booking:proceedToPayment'
              : 'booking:proceedToRegistration'
          )}
        </LinkButton>
      </div>
    </div>
  )
}

export default SlotPickerLater
