import React from 'react'
import { Trans } from 'react-i18next'
import type { BookingSlot } from '~/@types/graphql-police'
import Typography from '~/components/Typography'
import { useLocale } from '~/hooks/useLocale'
import { formatCheckupSlotTime } from '~/utils/check-up'
import { cn } from '~/utils/cn'

export type SlotsHoursListProps = {
  availableSlots: BookingSlot[]
  selectedSlot: BookingSlot | null
  onSelectSlot: (slot: BookingSlot) => void
}

const SlotsHoursList = ({
  availableSlots,
  selectedSlot,
  onSelectSlot
}: SlotsHoursListProps) => {
  const locale = useLocale()

  const handleChange = (slot: BookingSlot) => {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      event.preventDefault()

      return onSelectSlot(slot)
    }
  }

  return (
    <div className="flex h-full flex-col">
      <header className="w-full p-6 max-sm:pb-1">
        <Typography
          color="shy"
          component="p"
          variant="h6"
          className="sm:text-center"
        >
          <Trans
            values={{ count: availableSlots.length }}
            i18nKey="booking:slot"
          >
            <span className="font-medium text-black" />
          </Trans>
        </Typography>
      </header>
      <div className="flex flex-1 flex-col max-sm:overflow-x-auto">
        {availableSlots.length > 0 ? (
          <ul className="flex w-full flex-1 gap-x-2 gap-y-3 px-2 py-6 max-sm:pt-1 sm:flex-col sm:px-6">
            {availableSlots.map((slot) => {
              const startDate = new Date(slot.startDate)
              const endDate = new Date(slot.endDate)
              const isChecked = Boolean(slot.id === selectedSlot?.id)

              return (
                <li className="shrink-0" key={slot.id}>
                  <label
                    className={cn(
                      'block w-full cursor-pointer rounded-full border border-black/15 px-4 py-2 text-center text-body2 focus-within:border-black hover:border-black',
                      {
                        'border-black': isChecked
                      }
                    )}
                    color="secondary"
                  >
                    <input
                      type="radio"
                      name="slot"
                      onChange={handleChange(slot)}
                      value={slot.id}
                      checked={isChecked}
                      className="absolute h-0 w-0 opacity-0"
                    />
                    {formatCheckupSlotTime(startDate, endDate, {
                      locale
                    })}
                  </label>
                </li>
              )
            })}
          </ul>
        ) : (
          <div className="flex flex-1 items-center p-4">
            <Typography align="center" variant="subtitle2">
              Aucune réservation disponible pour le moment.
            </Typography>
          </div>
        )}
      </div>
    </div>
  )
}

export default SlotsHoursList
