import React from 'react'
import { useTranslation } from 'react-i18next'
import Spinner from '~/components/Spinner'
import Typography from '~/components/Typography'

const SlotsHoursLoading = () => {
  const { t } = useTranslation()

  return (
    <div className="flex h-full w-full items-center justify-center p-4">
      <div className="flex flex-col items-center gap-y-2">
        <Spinner className="text-black/50" />
        <Typography variant="subtitle2" align="center" color="shy">
          {t('booking:loadingAvailableSlots')}
        </Typography>
      </div>
    </div>
  )
}

export default SlotsHoursLoading
