import { useTranslation } from 'react-i18next'
import type { TFunction } from 'i18next'
import Check from '~/components/icons/Check'
import Download from '~/components/icons/Download'
import Link from '~/components/Link'
import LinkButton from '~/components/LinkButton'
import Typography from '~/components/Typography'
import { medias } from '~/constants/medias'
import { ROUTES } from '~/constants/routes'
import { parseSafeArrayStrings } from '~/helpers/string'
import { useLocale } from '~/hooks/useLocale'
import type { Locale } from '~/i18n/config'
import AsideResumePrice from '~/routes/($lang).subscription/payment/AsideResume/AsideResumePrice'
import { trackEvent, type TrackingEventName } from '~/utils/tracking'
import type { SlotPickContext } from '../SlotPicker.types'

const MEDIAS_FILES = (t: TFunction, locale: Locale) => {
  return [
    {
      label: t('common:examsList'),
      path: medias.pdf.exams[locale],
      clickEventName: 'DOWNLOAD_EXAMS_LIST_CLICK'
    },
    {
      label: t('common:biomarkersList'),
      path: medias.pdf.bio[locale],
      clickEventName: 'DOWNLOAD_BIOMARKERS_LIST_CLICK'
    }
  ] as const
}

export type SlotsAsideResumeProps = {
  context: SlotPickContext
  isExcludingTaxVisible: boolean
}

const SlotsAsideResume = ({
  context,
  isExcludingTaxVisible
}: SlotsAsideResumeProps) => {
  const { t } = useTranslation()
  const locale = useLocale()

  const resumeOffer = parseSafeArrayStrings(
    t('common:suscriptionPoints', {
      returnObjects: true
    })
  )

  const handleDownloadClick = (eventName: TrackingEventName) => {
    trackEvent(eventName, {
      page: context
    })
  }

  return (
    <div className="flex flex-col items-center gap-y-6">
      <div className="flex w-full flex-col rounded-md border-1 border-black/10 max-lg:text-center max-sm:text-left">
        <div className="flex flex-col gap-y-6 p-8">
          <div className="flex flex-col gap-y-2">
            <Typography variant="h6" component="h3" family="brand">
              {t('common:subscription')}
            </Typography>
            <Typography variant="h6" component="p" color="shy">
              {t('common:annualPayment')}
            </Typography>
          </div>
          <AsideResumePrice isExcludingTaxVisible={isExcludingTaxVisible} />
          <ul className="flex flex-col gap-y-3 sm:items-center lg:items-start">
            {resumeOffer.map((label) => {
              return (
                <li key={label}>
                  <Typography
                    variant="body2"
                    component="h4"
                    className="xs:block flex items-start gap-x-3 sm:flex sm:text-left"
                  >
                    <Check className="xs:mr-3 mt-1 inline-block align-middle text-black/30" />
                    {label}
                  </Typography>
                </li>
              )
            })}
          </ul>
        </div>
        <div className="flex flex-col gap-y-8 border-t border-black/10 px-8 py-6">
          <ul className="flex w-full flex-col gap-y-4 sm:items-center lg:items-start">
            {MEDIAS_FILES(t, locale).map((file) => {
              return (
                <li key={file.label} className="flex">
                  <Link
                    underline="hover"
                    className="flex items-center gap-x-1"
                    to={file.path}
                    target="_blank"
                    onClick={() => {
                      return handleDownloadClick(file.clickEventName)
                    }}
                  >
                    <span>{file.label}</span>
                    <Download />
                  </Link>
                </li>
              )
            })}
          </ul>
          <div className="mx-auto w-full max-lg:max-w-xl">
            <LinkButton
              to={ROUTES.contactUs}
              size="md"
              fullWidth
              color="secondary"
              rounded="full"
            >
              {t('common:anotherQuestion')}
            </LinkButton>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SlotsAsideResume
